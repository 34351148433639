import { handleActions, handleAction } from "redux-actions";
import {
  AUTH,
  SET_PROFILE,
  SET_CURRENT_ROUTE,
  LOGOUT,
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_SIDE_MENU_STATE,
  OPEN_USERS_FORM,
  CLOSE_USERS_FORM,
  EDIT_SELECTED_USER,
  USERS_FORM_TOOGLE_LOADING,
  SET_USER_LIST,
  ADD_NEW_USER,
  UPDATE_USER,
  DELETE_USER,
  OPEN_ALERT_FORM,
  CLOSE_ALERT_FORM,
  EDIT_SELECTED_ALERT,
  ALERT_FORM_TOOGLE_LOADING,
  SET_ALERT_LIST,
  ADD_NEW_ALERT,
  UPDATE_ALERT,
  DELETE_ALERT,
  SET_NOTIFY_LIST,
  ADD_NEW_NOTIFY,
  UPDATE_NOTIFY,
  DELETE_NOTIFY,
  OPEN_NOTIFY_FORM,
  CLOSE_NOTIFY_FORM,
  EDIT_SELECTED_NOTIFY,
  NOTIFY_FORM_TOOGLE_LOADING,
  SET_LOCATION_LIST,
  ADD_NEW_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  OPEN_LOCATION_FORM,
  CLOSE_LOCATION_FORM,
  EDIT_SELECTED_LOCATION,
  LOCATION_FORM_TOOGLE_LOADING,
  ADD_NEW_ESTABLISHMENT,
  SET_ESTABLISHMENT_LIST,
  UPDATE_ESTABLISHMENT,
  DELETE_ESTABLISHMENT,
  OPEN_ESTABLISHMENT_FORM,
  CLOSE_ESTABLISHMENT_FORM,
  EDIT_SELECTED_ESTABLISHMENT,
  ESTABLISHMENT_FORM_TOOGLE_LOADING,
  THEME,
} from "./../../constants";

/* BASIC */
export const authorization = handleActions(
  {
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false }),
  },
  null
);

/* PROFILE */
export const profile = handleActions(
  {
    [SET_PROFILE]: (state, action) => action.payload,
  },
  null
);

/* APP */
export const currentRoute = handleAction(
  SET_CURRENT_ROUTE,
  (state, action) => action.payload,
  {}
);

/* APP */
export const currentTheme = handleAction(
  THEME,
  (state, action) => action.payload,
  false
);

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
  { isCollapsedSideMenu: true }
);

export const users = handleActions(
  {
    [SET_USER_LIST]: (state, action) => action.payload,
    [ADD_NEW_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_USER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const usersForm = handleActions(
  {
    [OPEN_USERS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_USERS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_USER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [USERS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const alerts = handleActions(
  {
    [SET_ALERT_LIST]: (state, action) => action.payload,
    [ADD_NEW_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ALERT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const alertsForm = handleActions(
  {
    [OPEN_ALERT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ALERT_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_ALERT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ALERT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// NOTIFYS
export const notifys = handleActions(
  {
    [SET_NOTIFY_LIST]: (state, action) => action.payload,
    [ADD_NEW_NOTIFY]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_NOTIFY]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_NOTIFY]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const notifysForm = handleActions(
  {
    [OPEN_NOTIFY_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_NOTIFY_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_NOTIFY]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [NOTIFY_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const locations = handleActions(
  {
    [SET_LOCATION_LIST]: (state, action) => action.payload,
    [ADD_NEW_LOCATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_LOCATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_LOCATION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const locationsForm = handleActions(
  {
    [OPEN_LOCATION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_LOCATION_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_LOCATION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [LOCATION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);


// ESTABLISHMENTS
export const establishments = handleActions(
  {
    [SET_ESTABLISHMENT_LIST]: (state, action) => action.payload,
    [ADD_NEW_ESTABLISHMENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ESTABLISHMENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ESTABLISHMENT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const establishmentsForm = handleActions(
  {
    [OPEN_ESTABLISHMENT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ESTABLISHMENT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ESTABLISHMENT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ESTABLISHMENT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_NOTIFY,
  DELETE_NOTIFY,
  NOTIFY_FORM_TOOGLE_LOADING,
  SET_NOTIFY_LIST,
  SHOW_NOTIFICATION,
  UPDATE_NOTIFY,
} from "../constants";
import { formatNotifys } from "./settingsActionsUtils";

/* NOTIFY LIST */
export const fetchNotifys = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.notifys.list)
      .then((response) => {
        const notifys = formatNotifys(response.data);
        dispatch({
          type: SET_NOTIFY_LIST,
          payload: keyBy(notifys, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// NOTIFYS
export const createNotify = (newNotify) => {
  return async (dispatch) => {
    dispatch({ type: NOTIFY_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.notifys.create, newNotify)
      .then((response) => {
        const notify = formatNotifys(response.data);
        dispatch({ type: ADD_NEW_NOTIFY, payload: notify });
        dispatch({ type: NOTIFY_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Notificación creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: NOTIFY_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

// NOTIFYS
export const userMessage = (message) => {
  return async (dispatch) => {
    dispatch({ type: NOTIFY_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.notifys.message, message)
      .then((response) => {
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Notificación creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: NOTIFY_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateNotify = (updatedNotify) => {
  return async (dispatch) => {
    dispatch({ type: NOTIFY_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.notifys.edit}${updatedNotify?._id}`, updatedNotify)
      .then((response) => {
        const notify = formatNotifys(response.data);
        dispatch({ type: UPDATE_NOTIFY, payload: notify });
        dispatch({ type: NOTIFY_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Notificación actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: NOTIFY_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const sendNotify = (id) => {
  return async (dispatch) => {
    dispatch({ type: NOTIFY_FORM_TOOGLE_LOADING });
    const response = await axios
      .get(`${API.notifys.send}${id}`)
      .then((response) => {
        dispatch({ type: NOTIFY_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Notificación enviada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: NOTIFY_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteNotifys = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: NOTIFY_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.notifys.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_NOTIFY, payload: Ids });
        dispatch({ type: NOTIFY_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Notificación eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: NOTIFY_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

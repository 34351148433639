import { createSelector } from "reselect";
import moment from "moment";
import "moment/locale/es";
import { ROLES_RAW } from "../../constants/permissions";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
  (state) => state.authorization,
  (authorization) => authorization
);
export const getCurrentTheme = createSelector(
  (state) => state.currentTheme,
  (currentTheme) => currentTheme
);
export const getCurrentRoute = createSelector(
  (state) => state.currentRoute,
  (currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(
  getCurrentRoute,
  (route) => route.title
);
export const getSideMenuState = createSelector(
  (state) => state.app,
  (app) => app.isCollapsedSideMenu
);

/* PROFILE */
export const getUserProfile = createSelector(
  (state) => state.profile,
  (profile) => profile
);
export const currentUserId = createSelector(
  (state) => state.profile,
  (profile) => profile && profile.id
);
export const getCurrentUserRole = createSelector(getUserProfile, (profile) =>
  ROLES_RAW.includes(profile?.role) ? profile?.role : null
);

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

/* USERS */
export const getUsers = createSelector(
  (state) => state.users,
  (users) => users
);
export const getUsersForm = createSelector(
  (state) => state.usersForm,
  (form) => form
);

/* ALERT */
export const getAlerts = createSelector(
  (state) => state.alerts,
  (alerts) => alerts
);
export const getAlertsForm = createSelector(
  (state) => state.alertsForm,
  (form) => form
);

/* NOTIFYS */
export const getNotifys = createSelector(
  (state) => state.notifys,
  (notifys) => notifys
);
export const getNotifysForm = createSelector(
  (state) => state.notifysForm,
  (form) => form
);

/* LOCATIONS */
export const getLocations = createSelector(
  (state) => state.locations,
  (locations) => locations
);
export const getLocationForm = createSelector(
  (state) => state.locationsForm,
  (form) => form
);

/* ESTABLISHMENTS */
export const getEstablishments = createSelector(
  (state) => state.establishments,
  (establishments) => establishments
);
export const getEstablishmentsForm = createSelector(
  (state) => state.establishmentsForm,
  (form) => form
);

import ReactDOM from "react-dom";
import App from "./App";
import history from "./history";
import { Provider } from "react-redux";
import { store } from "./store";
import { Router } from "react-router-dom";
import MobileConsoleDebug from "./utils/MobileConsoleDebug";
import ConnectionHandler from "./connection/ConnectionHandler";
import NotificationsHandler from "./components/Notifications";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import "./styles/index.less";
import "./assets/fonts/index.less";
import "react-drop-zone/dist/styles.css";

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<App />
			<ConnectionHandler />
			<NotificationsHandler />
			<MobileConsoleDebug />
		</Router>
	</Provider>,
	document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

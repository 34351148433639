/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */

/* NOTIFY */
export const SET_NOTIFY_LIST = "SET_NOTIFY_LIST";
export const ADD_NEW_NOTIFY = "ADD_NEW_NOTIFY";
export const UPDATE_NOTIFY = "UPDATE_NOTIFY";
export const DELETE_NOTIFY = "DELETE_NOTIFY";
export const OPEN_NOTIFY_FORM = "OPEN_NOTIFY_FORM";
export const CLOSE_NOTIFY_FORM = "CLOSE_NOTIFY_FORM";
export const EDIT_SELECTED_NOTIFY = "EDIT_SELECTED_NOTIFY";
export const NOTIFY_FORM_TOOGLE_LOADING = "NOTIFY_FORM_TOOGLE_LOADING";
/* <---- NOTIFY ----> */

/* LOCATION */
export const SET_LOCATION_LIST = "SET_LOCATION_LIST";
export const ADD_NEW_LOCATION = "ADD_NEW_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const OPEN_LOCATION_FORM = "OPEN_LOCATION_FORM";
export const CLOSE_LOCATION_FORM = "CLOSE_LOCATION_FORM";
export const EDIT_SELECTED_LOCATION = "EDIT_SELECTED_LOCATION";
export const LOCATION_FORM_TOOGLE_LOADING = "LOCATION_FORM_TOOGLE_LOADING";
/* <---- LOCATION ----> */

/* ESTABLISHMENT */
export const SET_ESTABLISHMENT_LIST = "SET_ESTABLISHMENT_LIST";
export const ADD_NEW_ESTABLISHMENT = "ADD_NEW_ESTABLISHMENT";
export const UPDATE_ESTABLISHMENT = "UPDATE_ESTABLISHMENT";
export const DELETE_ESTABLISHMENT = "DELETE_ESTABLISHMENT";
export const OPEN_ESTABLISHMENT_FORM = "OPEN_ESTABLISHMENT_FORM";
export const CLOSE_ESTABLISHMENT_FORM = "CLOSE_ESTABLISHMENT_FORM";
export const EDIT_SELECTED_ESTABLISHMENT = "EDIT_SELECTED_ESTABLISHMENT";
export const ESTABLISHMENT_FORM_TOOGLE_LOADING = "ESTABLISHMENT_FORM_TOOGLE_LOADING";
/* <---- ESTABLISHMENT ----> */
// https://testing-core.deploy.kuarasoftware.es/

export const API = {
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
  notifys: {
    create: `/notify`,
    edit: `/notify/`,
    delete: `/notify/`,
    send: `/notify/`,
    list: `/notify`,
    message: `/userMessage`,
  },
  locations: {
    create: `/location`,
    edit: `/location/`,
    delete: `/location/`,
    list: `/location`,
  },
  establishments: {
    create: `/establishment`,
    edit: `/establishment/`,
    delete: `/establishment/`,
    list: `/establishment`,
  },
};
